import React, { useEffect, useState } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Container } from "../components/styledComponents"
import CenterBorderedHeader from "../components/centerBorderedHeader"
import WingedText from "../components/wingedText"
import { BigMobileFont, MediumMobileFont } from "../components/typography"
import { graphql } from "gatsby"
import LastCTASection from "../components/lastCTASection"
import styled from "styled-components"
import FlowMapImage from "../components/flowMapImage"
import { useAnimatedBorder } from "../helpers"
import Popup from "../components/popup"

const Category = styled.div`
  border: 1px solid ${(props) => props.theme.secondary};
  padding: 3rem 4rem;
  margin-right: 9.5rem;
  border-right: none;
  position: relative;
  ::before,
  ::after {
    content: "";
    position: absolute;
    display: block;
    width: 100%;
    height: 20px;
    border-right: 1px solid ${(props) => props.theme.secondary};
    right: 0;
    transition: transform 1s ease-out;
  }
  ::before {
    top: 0;
    transform: scaleY(${(props) => 1 + props.scale});
    transform-origin: top;
  }
  ::after {
    bottom: 0;
    transform: scaleY(${(props) => 1 - props.scale});
    transform-origin: bottom;
  }
`

const CategoriesGrid = styled.div`
  display: grid;
  align-items: center;
  margin-left: calc(14vw + 2rem);
  grid-template-columns: 2fr 1fr;
  margin-top: 90px;
  gap: 80px 2rem;
  @media (max-width: 1195px) {
    margin-left: 0;
  }
  @media (max-width: 900px) {
    grid-template-columns: 100%;
    gap: 40px;
  }
`

const WingsImageWrapper = styled.div`
  position: absolute;
  top: -100px;
  left: -5%;
  width: 23vw;
  @media (max-width: 1195px) {
    display: none;
  }
`

const calculateOrder = (index) => {
  if (index % 2 === 0) return index - 1
  return index + 1
}

const Services = ({ data }) => {
  const {
    categoriesSectionTitle,
    firstSectionContentText,
    firstSectionContentTitle,
    firstSectionTitle,
    lastCTASectionButtonText,
    lastCTASectionButtonUrl,
    lastCTASectionText,
    lastCTASectionTitle,
    categories,
  } = data.wordpressPage.cmb2.metabox_services
  const [scale, boxRef] = useAnimatedBorder(2)
  const [innerWidth, setInnerWidth] = useState(0)
  useEffect(() => {
    setInnerWidth(window.innerWidth)
  }, [])
  return (
    <Layout>
      <SEO
        meta={data.wordpressPage.yoast_meta}
        title={data.wordpressPage.title}
        path="/services"
      />
      <Container>
        <CenterBorderedHeader isH1>
          <BigMobileFont desktopFontSize="2.5rem">
            {firstSectionTitle}
          </BigMobileFont>
        </CenterBorderedHeader>
        <WingedText
          title={firstSectionContentTitle}
          text={firstSectionContentText}
        />
        <CenterBorderedHeader>
          <BigMobileFont desktopFontSize="2.5rem">
            {categoriesSectionTitle}
          </BigMobileFont>
        </CenterBorderedHeader>
      </Container>
      <div style={{ position: "relative" }}>
        <WingsImageWrapper>
          <FlowMapImage
            imagePublicURL={data.file.publicURL}
            size={{ width: 276, height: 1169 }}
          />
        </WingsImageWrapper>
        <Container>
          {categories.map((category, index) => {
            return (
              <CategoriesGrid key={category.title}>
                <Category
                  ref={boxRef}
                  scale={scale}
                  style={
                    innerWidth < 900
                      ? { order: calculateOrder(2 * index) }
                      : null
                  }
                >
                  <div style={{ marginRight: "-12.5rem" }}>
                    <h3 style={{ marginBottom: "0.75rem" }}>
                      <MediumMobileFont>{category.title}</MediumMobileFont>
                    </h3>
                    <p
                      dangerouslySetInnerHTML={{ __html: category.text }}
                      style={{ lineHeight: "1.625", marginBottom: "0" }}
                    ></p>
                  </div>
                </Category>
                <div
                  style={
                    innerWidth < 900
                      ? {
                          order: calculateOrder(2 * index + 1),
                          lineHeight: "2.5rem",
                          fontWeight: "600",
                          whiteSpace: "pre",
                          marginLeft: "2rem",
                        }
                      : {
                          lineHeight: "2.5rem",
                          fontWeight: "600",
                          whiteSpace: "pre",
                          marginLeft: "2rem",
                        }
                  }
                >
                  {category.subservices.map((subservice) => {
                    return `${subservice}\n`
                  })}
                </div>
              </CategoriesGrid>
            )
          })}
        </Container>
      </div>

      <LastCTASection
        title={lastCTASectionTitle}
        text={lastCTASectionText}
        buttonUrl={lastCTASectionButtonUrl}
        buttonText={lastCTASectionButtonText}
      />
      <Popup />
    </Layout>
  )
}

export const query = graphql`
  query ServicesPageQuery {
    file(name: { eq: "mediumWings" }) {
      publicURL
    }
    wordpressPage(template: { eq: "page-templates/page-services.php" }) {
      title
      yoast_meta {
        content {
          source_url
        }
        normalized_content
        name
        property
      }
      cmb2 {
        metabox_services {
          categoriesSectionTitle
          firstSectionContentText
          firstSectionContentTitle
          firstSectionTitle
          lastCTASectionButtonText
          lastCTASectionButtonUrl
          lastCTASectionText
          lastCTASectionTitle
          categories {
            text
            subservices
            title
          }
        }
      }
    }
  }
`

export default Services
